
import {
    createVNode
} from "vue";
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons-vue";
import Editor from "./editor";

export default {
    name: "BasicAddressBook",
    components: {
        DeleteOutlined,
        PlusOutlined,
        QuestionCircleOutlined,
        Editor
    },
    data() {
        return {
            url: "/admin/currency/list",
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 导入Loading状态
            import_loading: false
        };
    },
    computed: {
        columns() {
            return [{
                    key: "index",
                    width: 48,
                    customRender: ({
                            index
                        }) =>
                        this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t("货币名称"),
                    dataIndex: "name",
                    width: 200,
                    sorter: true
                },
                {
                    title: this.$t("货币代码"),
                    dataIndex: "currency",
                    width: 120,
                    sorter: true
                },
                {
                    dataIndex: "exchange_rate",
                    align: "center",
                    width: 120,
                    sorter: true,
                    customRender: ({
                        record
                    }) => {
                        return record.exchange_rate.toFixed(4);
                    },
                    slots: {
                        title: "exchange_rate_title"
                    }
                },
                {
                    title: this.$t("货币符号"),
                    dataIndex: "symbol",
                    align: "center",
                    width: 120,
                    sorter: true
                },
                {
                    title: this.$t("排序"),
                    dataIndex: "sort",
                    align: "center",
                    width: 80,
                    sorter: true,
                    defaultSortOrder: 'ascend',
                },
                {
                    title: this.$t("操作人"),
                    dataIndex: "creator",
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t("创建时间"),
                    dataIndex: "create_time",
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t("操作"),
                    key: "action",
                    slots: {
                        customRender: "action"
                    }
                }
            ];
        }
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading("请求中...", 0);

            this.$http
                .post("/admin/currency/delete", {
                    ids: [row.id]
                })
                .then(res => {
                    hide();
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    hide();
                    this.$message.error(e.message);
                });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error("请至少选择一条数据");
            }

            this.$confirm({
                title: this.$t("提示"),
                content: this.$t("确定要删除选中的数据吗?"),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading("请求中...", 0);

                    this.$http
                        .post("/admin/currency/delete", {
                            ids: this.selection.map(d => d.id)
                        })
                        .then(res => {
                            hide();
                            if (res.data.code === 0) {
                                this.$message.success(res.data.msg);
                                this.reload();
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(e => {
                            hide();
                            this.$message.error(e.message);
                        });
                }
            });
        },
        /**
         * 导入
         */
        importcurrency() {
            this.import_loading = true;

            this.$http
                .get(`/admin/currency/import_countries`)
                .then(res => {
                    this.import_loading = false;

                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    this.import_loading = false;

                    this.$message.error(e.message);
                });
        }
    }
};
